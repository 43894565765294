
@import "compass/reset";
@import "config";
@import "common";
@import "compass/css3";
@import "compass/utilities";

* {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: $azul-acs-medio;
  border-color: $azul-acs-medio;
  color: $blanco;
}
.navbar-default {
  background-color: transparent;
  border: none !important;
}
.navbar {
  position: relative;
  min-height: 0 !important;
  margin-bottom: 0 !important;
  border: 0px solid transparent;
  padding-top: 25px;
}
.navbar-collapse {
  padding-right: 0 !important;
  padding-left: 0 !important;
  overflow-x: visible;

}
@media (min-width: 768px){
  .navbar {
    border-radius: 0px;
    //max-height: 105px !important;
  }
  .navbar {
    position: relative;
    min-height: 0px;
    margin-bottom: 0px !important;
    border: 0px solid transparent;
  }
}

.navbar-default .navbar-nav > li > a {
  color: $blanco;
  &:hover{
    color: $blanco;
  }
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: $blanco;
  background: none !important;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: $blanco;
  background: none !important;
}


#w0-collapse{
  position: relative;
  z-index: 9999;
}




.noticias-pager , .publicaciones-pager{

  li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    z-index: 3;
    color: $blanco;
    background-color: $azul-acs-oscuro;
    border-color: $azul-acs-oscuro;
  }

  li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    z-index: 3;
    color: $blanco !important;
    background-color: $azul-acs-oscuro !important;
    border-color: $azul-acs-oscuro !important;
  }

}
.publicaciones-pager{



}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  text-decoration: none;
  background-color: $azul-acs-medio;
}
.dropdown-menu li.active{
  a{
    font-weight: bolder !important;

    &:before {
      content: "";
      width: 0px !important;
      height: 0px;
      bottom: 0px;

    }

  }
}

#w1-collapse[aria-expanded="true"]{
  position: absolute;
  background: $azul-acs-medio;
  z-index: 99999;
  width: 100%;
  height: auto;
  overflow: auto;
  min-height: 300px;
  ul{
    float: none !important;
    li{
      display: block;
      text-align: center;
    }
    
  }
}



.dropdown:hover .dropdown-menu {

  //background: $azul-acs-medio;
}
